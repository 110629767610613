import { acceptHMRUpdate, defineStore } from 'pinia'

import type { StoreInfluencerInteraction } from '~/types/influencerInteraction'

const state = () => ({
  list: [] as StoreInfluencerInteraction[],
})

export type InfluencersInteractionsState = ReturnType<typeof state>

export const useInfluencersInteractionsStore = defineStore(
  'influencersInteractions',
  {
    state: (): InfluencersInteractionsState => ({ ...state() }),
    actions: {
      SET_LIST(newList: StoreInfluencerInteraction[]) {
        this.list = newList
      },
      FETCH() {
        if (this.list.length) return Promise.resolve()

        return $coreFetch
          .$get<StoreInfluencerInteraction[]>('/influencer/interactions/')
          .then((resp) => this.SET_LIST(resp))
      },
    },
    getters: {
      GET_INTERACTION_FROM_INFLUENCER_ID(state) {
        return function (
          influencerId: number,
        ): StoreInfluencerInteraction | undefined {
          return state.list.find(
            (influencerInteraction) =>
              influencerInteraction.influencer_id === influencerId,
          )
        }
      },
    },
  },
)

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useInfluencersInteractionsStore, import.meta.hot),
  )
}
